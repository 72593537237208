import _export from "../internals/export";
import _global3 from "../internals/global";
import _fails from "../internals/fails";
import _isArray from "../internals/is-array";
import _isObject from "../internals/is-object";
import _toObject from "../internals/to-object";
import _lengthOfArrayLike from "../internals/length-of-array-like";
import _createProperty from "../internals/create-property";
import _arraySpeciesCreate from "../internals/array-species-create";
import _arrayMethodHasSpeciesSupport from "../internals/array-method-has-species-support";
import _wellKnownSymbol from "../internals/well-known-symbol";
import _engineV8Version from "../internals/engine-v8-version";
var $ = _export;
var _global2 = _global3;
var fails = _fails;
var isArray = _isArray;
var isObject = _isObject;
var toObject = _toObject;
var lengthOfArrayLike = _lengthOfArrayLike;
var createProperty = _createProperty;
var arraySpeciesCreate = _arraySpeciesCreate;
var arrayMethodHasSpeciesSupport = _arrayMethodHasSpeciesSupport;
var wellKnownSymbol = _wellKnownSymbol;
var V8_VERSION = _engineV8Version;
var IS_CONCAT_SPREADABLE = wellKnownSymbol("isConcatSpreadable");
var MAX_SAFE_INTEGER = 9007199254740991;
var MAXIMUM_ALLOWED_INDEX_EXCEEDED = "Maximum allowed index exceeded";
var TypeError = _global2.TypeError; // We can't use this feature detection in V8 since it causes
// deoptimization and serious performance degradation
// https://github.com/zloirock/core-js/issues/679

var IS_CONCAT_SPREADABLE_SUPPORT = V8_VERSION >= 51 || !fails(function () {
  var array = [];
  array[IS_CONCAT_SPREADABLE] = false;
  return array.concat()[0] !== array;
});
var SPECIES_SUPPORT = arrayMethodHasSpeciesSupport("concat");

var isConcatSpreadable = function (O) {
  if (!isObject(O)) return false;
  var spreadable = O[IS_CONCAT_SPREADABLE];
  return spreadable !== undefined ? !!spreadable : isArray(O);
};

var FORCED = !IS_CONCAT_SPREADABLE_SUPPORT || !SPECIES_SUPPORT; // `Array.prototype.concat` method
// https://tc39.es/ecma262/#sec-array.prototype.concat
// with adding support of @@isConcatSpreadable and @@species

$({
  target: "Array",
  proto: true,
  arity: 1,
  forced: FORCED
}, {
  // eslint-disable-next-line no-unused-vars -- required for `.length`
  concat: function concat(arg) {
    var O = toObject(this);
    var A = arraySpeciesCreate(O, 0);
    var n = 0;
    var i, k, length, len, E;

    for (i = -1, length = arguments.length; i < length; i++) {
      E = i === -1 ? O : arguments[i];

      if (isConcatSpreadable(E)) {
        len = lengthOfArrayLike(E);
        if (n + len > MAX_SAFE_INTEGER) throw TypeError(MAXIMUM_ALLOWED_INDEX_EXCEEDED);

        for (k = 0; k < len; k++, n++) if (k in E) createProperty(A, n, E[k]);
      } else {
        if (n >= MAX_SAFE_INTEGER) throw TypeError(MAXIMUM_ALLOWED_INDEX_EXCEEDED);
        createProperty(A, n++, E);
      }
    }

    A.length = n;
    return A;
  }
});
export default {};